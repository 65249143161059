import React, { useState } from 'react';
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import { getMemberId, patchMember } from '../../../utils/catalinaRequests';
import { refundPages } from './RefundPagesEnum';
import InputText from '../../components/Form/Input/InputText';
import ErrorMessage from '../../components/Form/Error/ErrorMessage';
import Loading from '../../components/Loading/Loading';
import { onEnter } from '../../../utils/utils';
import { useForm } from 'react-hook-form';

function RefundPaypal({ selectPage, backgroundLayerStyle }) {
  const { t } = useTranslation('message');
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [displayInputErrors, setDisplayInputErrors] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  let values = getValues();

  let isSameMail = values.email === values.confirmEmail;

  function onSubmit() {
    if (isValid && isSameMail) {
      setDisplayInputErrors(false);
      setLoading(true);
      let data = {
        beneficiary: '',
        bank_bic: '',
        bank_iban: '',
        paypal_id: values.email,
      };
      patchMember(data, getMemberId())
        .then(() => {
          setLoading(false);
          navigate('success-email');
        })
        .catch((err) => {
          setLoading(false);
          setRequestError(true);
        });
    } else {
      setDisplayInputErrors(true);
    }
  }

  function refundPaypalContent() {
    return (
      <>
        <div className="subtitle">{t('refund.paypal.title')}</div>
        <div className="refund-options">
          <form onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => onEnter(e)}>
            <InputText
              type="text"
              name="email"
              placeholder={t('refund.paypal.email')}
              autoComplete="off"
              pattern={{
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'format incorrect',
              }}
              required
              errorMessage={t('refund.intro.error')}
              displayErrors={displayInputErrors}
              register={register}
              watch={watch}
            />
            <InputText
              type="text"
              name="confirmEmail"
              placeholder={t('refund.paypal.confirmEmail')}
              autoComplete="off"
              pattern={{
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'format incorrect',
              }}
              required
              errorMessage={t('refund.intro.error')}
              displayErrors={displayInputErrors}
              register={register}
              watch={watch}
            />
            <>
              {displayInputErrors && !isSameMail && (
                <ErrorMessage message={t('refund.paypal.error')} />
              )}
              {isLoading && <Loading />}
              {requestError && (
                <ErrorMessage message={t('refund.intro.errorRequest')} />
              )}
            </>
            <div className="info">{t('refund.paypal.info')}</div>
            <div className="button-area">
              <Button
                text={t('general.back')}
                doAction={() => selectPage(refundPages.CHOICES)}
              />
              <Button
                text={t('general.next')}
                disabled={!isValid || !isDirty || !isSameMail}
                type="submit"
              />
            </div>
          </form>
        </div>
      </>
    );
  }

  return (
    <SlotMachine
      content={refundPaypalContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default RefundPaypal;
