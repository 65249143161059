import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import shopConfig from '../../../shop/config/config.json';
import { getOfferId, getRetailerId } from '../../../utils/catalinaRequests';
import { encryptionWithCryptoJS } from '../../../utils/appApiConfiguration';
import Button from '../../components/Button/Button';
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import './assets/styles.scss';
import LayoutContent from './components/LayoutContent';

function Teasing({ offerr: offer }) {
  const { t } = useTranslation('message');
  const offerId = getOfferId();
  const retailerId = getRetailerId();

  const startDate = offer?.displayDate || offer?.startDate;
  const isModeInStore = shopConfig?.isModeInStore;
  const currentDate = new Date().getTime();
  const displayDate = offer?.displayDate
    ? Moment(offer.displayDate, 'DD-MM-YYYY').valueOf()
    : null;

  // Détermine si le bouton "Accéder au jeu" doit être affiché :
  // - Le mode en magasin doit être actif (isModeInStore)
  // - ET soit il n'y a pas de date d'affichage définie,
  //   soit la date actuelle est égale ou postérieure à la date d'affichage
  const shouldDisplay =
    isModeInStore && (!displayDate || currentDate >= displayDate);

  useEffect(() => {
    //block previous click
    window.history.pushState({}, '');
    sessionStorage.setItem('teasing', 'true');
    const rootEl = document.getElementById('main-container');
    rootEl.style.overflow = 'scroll';
    rootEl.style.height = '100vh';

    // remove styles on unmounting
    return () => {
      rootEl.style.overflow = '';
      rootEl.style.height = '';
    };
  }, []);

  function getFinalUrl() {
    const params = {
      offerId,
      retailerId,
      listOffers: offerId,
    };

    if (shopConfig?.isModeInStore) {
      params.receipt = true;
    }
    if (process.env.NODE_ENV === 'production') {
      sessionStorage.removeItem('init');
      const urlEncrypted = encryptionWithCryptoJS(JSON.stringify(params));
      const urlEncoded = encodeURIComponent(urlEncrypted);
      const url = `?info=${urlEncoded}`;
      return `/${url}`;
    } else {
      // convert obj to plain params in url
      return `/?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`;
    }
  }

  function TeasingContent() {
    return (
      <div className="game">
        <div className="info-part">
          <div className="txt-date">
            {' '}
            {t('landing.gamePeriod', {
              start: startDate,
              end: offer?.endDate,
            })}
          </div>
          <div className="title">{offer?.offerTitle}</div>
          <div className="description">{offer?.offerDescription}</div>
        </div>

        <div className="button-area">
          {shouldDisplay ? (
            <Button
              text={'Accéder au jeu'}
              className="button-teasing black-button"
              to={getFinalUrl()}
            />
          ) : null}
          {offer?.detail_url ? (
            <Button
              className="button-teasing"
              text={'Voir la boutique'}
              doAction={() => (document.location = offer?.detail_url)}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="layout-teasing">
      <SlotMachine content={TeasingContent()} />
      <LayoutContent offer={offer} />
    </div>
  );
}

export default Teasing;
