import React, { useState } from 'react';
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import './assets/styles.scss';
import Button from '../../components/Button/Button';
import { refundPages } from './RefundPagesEnum';
import { getMemberId, patchMember } from '../../../utils/catalinaRequests';
import InputText from '../../components/Form/Input/InputText';
import ErrorMessage from '../../components/Form/Error/ErrorMessage';
import Loading from '../../components/Loading/Loading';
import { useForm } from 'react-hook-form';
import { onEnter } from '../../../utils/utils';

function RefundBank({ selectPage, backgroundLayerStyle }) {
  const { t } = useTranslation('message');
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState(false);
  const [displayInputErrors, setDisplayInputErrors] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  function onSubmit() {
    if (isValid) {
      let name = getValues('name');
      let iban = getValues('iban');
      setDisplayInputErrors(false);
      setLoading(true);
      let data = {
        beneficiary: name,
        bank_iban: iban,
        paypal_id: '',
      };
      patchMember(data, getMemberId())
        .then((res) => {
          setLoading(false);
          navigate('success-email');
        })
        .catch((err) => {
          setRequestError(true);
          setLoading(false);
        });
    } else {
      setDisplayInputErrors(true);
    }
  }

  function RefundIntroContent() {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => onEnter(e)}>
          <div className="subtitle">{t('refund.bank.title')}</div>
          <InputText
            name="name"
            required
            type="text"
            placeholder={t('refund.bank.name')}
            autoComplete="off"
            errorMessage={t('refund.bank.nameerror')}
            displayErrors={displayInputErrors}
            register={register}
            watch={watch}
          />

          <InputText
            name="iban"
            type="text"
            required
            placeholder={t('refund.bank.iban')}
            autoComplete="off"
            pattern={{
              value:
                /^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/i,
              message: 'format incorrect',
            }}
            errorMessage={t('refund.bank.ibanerror')}
            displayErrors={displayInputErrors}
            register={register}
            watch={watch}
          />
          {isLoading && <Loading />}
          {requestError && (
            <ErrorMessage message={t('refund.intro.errorRequest')} />
          )}
          <div className="info">{t('refund.bank.info')}</div>
          <div className="button-area">
            <Button
              text={t('general.back')}
              doAction={() => selectPage(refundPages.CHOICES)}
            />
            <Button
              text={t('general.next')}
              disabled={!isValid || !isDirty}
              type="submit"
            />
          </div>
        </form>
      </>
    );
  }

  return (
    <SlotMachine
      content={RefundIntroContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default RefundBank;
