// Dans un fichier séparé, par exemple FormCollectInfo.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputText from '../../components/Form/Input/InputText';
import Button from '../../components/Button/Button';
import { onEnter } from '../../../utils/utils';
import storeList from '../../../shop/config/storeList.json';
import config from '../../../shop/config/config.json';
import Checkbox from '../../components/Checkbox/Checkbox';

const FormCollectInfo = ({ onSubmit, loading }) => {
  const [t] = useTranslation('message');
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  const newsletter = watch('newsletter');
  const legal = watch('legal');

  return (
    <div className="collect-info-container">
      <form onSubmit={handleSubmit(onSubmit)} onKeyUp={onEnter}>
        <div className="subtitle">{t('collectInfo.title')}</div>
        {config.collectFields.includes('name') ? (
          <div className="container-line-input">
            <InputText
              name="firstName"
              type="text"
              required
              placeholder={t('landing.firstName')}
              autoComplete="off"
              errorMessage={t('refund.intro.error')}
              register={register}
              watch={watch}
            />
            <InputText
              name="lastName"
              type="text"
              required
              placeholder={t('landing.lastName')}
              autoComplete="off"
              errorMessage={t('refund.intro.error')}
              register={register}
              watch={watch}
            />
          </div>
        ) : null}
        {config.collectFields.includes('city') && storeList ? (
          <div className="container-line-input">
            <InputText
              name="street"
              type="text"
              placeholder={t('collectInfo.city')}
              autoComplete="off"
              required
              setValue={setValue}
              autocompleteData={storeList}
              errorMessage={t('refund.intro.error')}
              register={register}
              watch={watch}
              setError={setError}
            />
          </div>
        ) : null}
        {config.collectFields.includes('email') ? (
          <div className="container-line-input">
            <InputText
              name="email"
              type="text"
              placeholder={t('landing.email')}
              autoComplete="off"
              pattern={{
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'format incorrect',
              }}
              required
              errorMessage={t('refund.intro.error')}
              register={register}
              watch={watch}
              label={t('landing.contactInfoDesc')}
            />
          </div>
        ) : null}

        <div className="container-checkbox">
          <div className="container-line-input">
            <div className="collect-line">
              {config.collectFields.includes('optin_legal') ? (
                <Checkbox
                  label={t('collectInfo.legal')}
                  setChecked={() => setValue('legal', !legal)}
                  id="legal"
                  required
                  checked={legal}
                />
              ) : null}
            </div>
          </div>

          <div className="container-line-input">
            {config.collectFields.includes('news_optin_partners') ? (
              <div className="collect-line">
                <Checkbox
                  label={config?.collectNewsletterMessage}
                  setChecked={() => setValue('newsletter', !newsletter)}
                  id="newsletter"
                  checked={newsletter}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="button-area">
          <Button
            text={t('general.validate')}
            disabled={
              !isValid ||
              !isDirty ||
              loading ||
              (config.collectFields.includes('optin_legal') && !legal)
            }
            type="submit"
          />
        </div>
        <span className="mandatory-fields">
          {t('collectInfo.mandatoryFields')}
        </span>
      </form>
    </div>
  );
};

export default FormCollectInfo;
