import React from 'react';
import './assets/style.scss';
import { Link } from '@reach/router';
import config from '../../../shop/config/config.json';

function Logo({ displayLogo }) {
  let logoPartner = config?.logoPartner;

  let finalLogo = logoPartner?.length ? logoPartner : '';

  return (
    displayLogo && (
      <Link to="/" className="logo" id="main-logo">
        <img src={finalLogo} alt="" />
      </Link>
    )
  );
}

export default Logo;
