import 'whatwg-fetch';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.text().then((text) => {
    try {
      const jsonResponse = JSON.parse(text);
      return { response, jsonResponse };
    } catch (err) {
      return { response, jsonResponse: text };
    }
  });
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response, jsonResponse) {
  jsonResponse.status = response.status;
  if (response.status >= 200 && response.status < 300) {
    return jsonResponse;
  }
  const error = new Error(response.statusText);
  error.response = response;
  error.responseJSON = jsonResponse || {};
  error.responseJSON.statusCode = response.status;
  throw error;
}

const getPrefix = (url) => {
  return process.env.NODE_ENV === 'development' && url.startsWith('/')
    ? process.env.API_URL
    : '';
};

const handleGetMethod = (options, url) => {
  if (isIEBrowser()) {
    options.params = { ...options.params, nocache: Date.now() };
  }
  return appendParamsToUrl(url, options.params);
};

const isIEBrowser = () => {
  return (
    typeof navigator !== 'undefined' &&
    /msie|trident|edge/i.test(navigator.userAgent)
  );
};

const appendParamsToUrl = (url, params) => {
  if (!isPlainObject(params) || Object.keys(params).length === 0) return url;
  const query = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');
  return `${url}?${query}`;
};

const handleRequestBody = (options) => {
  if (options.bypassBodyControls) {
    delete options.bypassBodyControls;
    return options;
  }

  if (isPlainObject(options.body)) {
    if (options.type === 'formUrlEncoded') {
      options.body = Object.entries(options.body)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      options.headers['Content-Type'] =
        'application/x-www-form-urlencoded; charset=utf-8';
    } else {
      options.body = JSON.stringify(options.body);
      options.headers['Content-Type'] = 'application/json';
    }
  } else if (Array.isArray(options.body)) {
    options.body = JSON.stringify(options.body);
    options.headers['Content-Type'] = 'application/json';
  } else if (options.method === 'POST') {
    options.body = {};
    options.headers['Content-Type'] = 'application/json';
  }

  return options;
};

const addAuthHeaders = (options) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  options.headers['Accept-Language'] =
    sessionStorage.getItem('language') ?? 'en-EN';
  return options;
};

/**
 * Requests a URL, returning a promise
 * @param  {string} url         The URL we want to request
 * @param  {object} callOptions The options we want to pass to "fetch"
 * @return {object}             The response data
 */

export default function request(url, callOptions = null) {
  let pUrl = getPrefix(url) + url;
  let options = {
    headers: {},
    mode: 'cors',
    crossDomain: true,
    ...callOptions,
  };

  if (!options.method || options.method.toUpperCase() === 'GET') {
    pUrl = handleGetMethod(options, pUrl);
    delete options.params;
  }

  options = handleRequestBody(options);
  options = addAuthHeaders(options);

  return fetch(pUrl, options)
    .then(parseJSON)
    .then(({ response, jsonResponse }) => checkStatus(response, jsonResponse));
}
