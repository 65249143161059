import React from 'react';
import { Link } from '@reach/router';
import { getOfferBackgroundColor } from '../../../utils/catalinaRequests';
import { defaultBackgroundColor } from '../../../utils/clientConfig';
import './assets/style.scss';

function Button({ text, disabled, doAction, to, type, className }) {
  const color = getOfferBackgroundColor() || defaultBackgroundColor;
  console.log(color);
  if (to) {
    if (to.includes('http')) {
      return (
        <a
          className={`play-button  ${disabled ? 'disabled' : ''} ${className}`}
          href={to}
        >
          {text}
        </a>
      );
    }
    return (
      <Link
        className={`play-button  ${disabled ? 'disabled' : ''} ${className}`}
        to={to}
      >
        {text}
      </Link>
    );
  }
  return (
    <button
      className={`play-button  ${disabled ? 'disabled' : ''} ${className}`}
      type={type ? type : 'button'}
      onClick={doAction}
      style={{ color: color }}
    >
      {text}
    </button>
  );
}

export default Button;
