import React from 'react';

import './asset/style.scss';

function RadioButton({ checkAction, title, checked }) {
  return (
    <div className="radio" onClick={() => checkAction()}>
      <div className={`radioBox ${checked ? 'checked' : ''}`}>
        {checked ? <div className="circle" /> : null}
      </div>
      <div className={`radioTitle ${checked ? 'checked-text' : ''}`}>
        {title}
      </div>
    </div>
  );
}

export default RadioButton;
