import React, { useState } from 'react';
import { useNavigate, useParams } from '@reach/router';
import config from '../../../shop/config/config.json';
import FormCollectInfo from './FormCollectInfo'; // Assurez-vous d'ajuster le chemin d'importation
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import Loading from '../../components/Loading/Loading';
import {
  cryptedAuthentication,
  getMemberId,
  getOfferId,
  patchMember,
  getOffers,
} from '../../../utils/catalinaRequests';
import './assets/styles.scss';

export default function CollectInfo({ loaded }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  async function onSubmit(data) {
    setLoading(true);
    try {
      await cryptedAuthentication(data.email);
      let body = {};
      if (config.collectFields.includes('name')) {
        const names = [
          data.lastName.toLowerCase().trim(),
          data.firstName.toLowerCase().trim(),
        ];
        body = {
          lastname: names[0],
          firstname: names[1],
          email: data.email.toLowerCase().trim(),
          ...body,
        };
      }

      if (config.collectFields.includes('city')) {
        body = {
          city: `${data.street.toLowerCase()} - ${data.street_info.toLowerCase()}`,
          ...body,
        };
      }

      if (config.collectFields.includes('news_optin_partners')) {
        body = { news_optin_partners: data.newsletter, ...body };
      }

      await patchMember(body, getMemberId());

      let dataOffer = await getOffers(getOfferId());
      if (dataOffer?.quizz?.id) {
        return navigate(`collect-info-quality/${dataOffer.quizz.id}`);
      }
      return navigate(`in-store`);
    } catch (e) {
      console.error('ERROR :', e);
    } finally {
      setLoading(false);
    }
  }

  if (!loaded) {
    return <Loading />;
  }

  return (
    <SlotMachine
      content={<FormCollectInfo onSubmit={onSubmit} loading={loading} />}
    />
  );
}
