import React, { useEffect } from 'react';
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import { useTranslation } from 'react-i18next';
import eye from '../../../shop/assets/img/eye.png';
import lips from '../../../shop/assets/img/lips.png';
import { getOfferId, getRetailerId } from '../../../utils/catalinaRequests';
import '../../../game/resources/assets/styles/common.scss';
import config from './../../../shop/config/config.json';

function CanNotPlay({ redirect }) {
  const { t } = useTranslation('message');
  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname + window.location.search,
        title: 'cannot_play',
        offer: getOfferId(),
        retailer: getRetailerId(),
        canal: 'e-commerce',
      },
    });
  }, []);

  function CanNotPlayContent() {
    return (
      <div
        className="wrapper"
        style={redirect ? { backgroundColor: 'rgba(255, 255, 255, 0.4)' } : {}}
      >
        <div className="content-up">
          <img src={eye} alt="" />{' '}
          <div className="title">
            {t('general.sorry')}
            <br /> {t('cannot.canNotPlay')}
          </div>
          <img src={eye} alt="" />
        </div>
        <div className="description">
          {config?.ErrorMessage?.desc
            ? config?.ErrorMessage?.desc
            : t('cannot.description')}
        </div>
        <img className="lips" src={lips} alt="" />
      </div>
    );
  }

  return <SlotMachine content={CanNotPlayContent()} />;
}

export default CanNotPlay;
