import React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  WhatsappIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';
import './assets/style.scss';

function ShareComponent({ type: resultMode = 'win' }) {
  const { t } = useTranslation('message');
  const offerTitle = sessionStorage.getItem('offerTitle');
  const brand = sessionStorage.getItem('partnerName');

  const retailerPage = process.env.REACT_APP_HOME_SHOPPING_URL;
  const detail_url = `${retailerPage}/${brand}`;

  const buttonProps = {
    size: 50,
    borderRadius: 20,
    iconFillColor: 'black',
    bgStyle: {
      fill: 'white',
    },
  };

  const facebookText = {
    win: t('win.shareFb', {
      retailerPage: retailerPage,
      offerTitle: offerTitle,
      detail_url: detail_url,
      brand: brand,
    }),
    lost: t('lost.shareFb', {
      retailerPage: retailerPage,
      offerTitle: offerTitle,
      detail_url: detail_url,
      brand: brand,
    }),
  };

  const whatsappText = {
    win: t('win.shareWa', {
      retailerPage: retailerPage,
      offerTitle: offerTitle,
      detail_url: detail_url,
      brand: brand,
    }),
    lost: t('lost.shareWa', {
      retailerPage: retailerPage,
      offerTitle: offerTitle,
      detail_url: detail_url,
      brand: brand,
    }),
  };

  const emailText = {
    subject: {
      win: t('win.shareEmailSubject'),
      lost: t('lost.shareEmailSubject'),
    },
    win: t('win.shareEmail', {
      retailerPage: retailerPage,
      offerTitle: offerTitle,
      detail_url: detail_url,
      brand: brand,
    }),
    lost: t('lost.shareEmail', {
      retailerPage: retailerPage,
      offerTitle: offerTitle,
      detail_url: detail_url,
      brand: brand,
    }),
  };

  return (
    <div className="share-container">
      <div className="subtitle">
        {resultMode === 'win' ? t('win.share') : t('lost.share')}
      </div>
      <div className="share-btn-row">
        <EmailShareButton
          children={<EmailIcon {...buttonProps} />}
          subject={emailText?.subject[resultMode]}
          body={emailText[resultMode]}
          url={detail_url}
        />

        <WhatsappShareButton
          children={<WhatsappIcon {...buttonProps} />}
          url={detail_url}
          title={whatsappText[resultMode]}
        />
        <FacebookShareButton
          children={<FacebookIcon {...buttonProps} />}
          url={detail_url}
          quote={facebookText[resultMode]}
        />
        <FacebookMessengerShareButton
          children={<FacebookMessengerIcon {...buttonProps} />}
          url={detail_url}
          appId="291494419107518"
          redirectUri={detail_url}
        />
      </div>
    </div>
  );
}

export default ShareComponent;
