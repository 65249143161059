import config from '../shop/config/config.json';
import { getHolderRef, receiptInStoreMode } from './catalinaRequests';

export function withRefundFlow() {
  return enableIban() || enablePaypal() || enableLater();
}
export function withDynamicReward() {
  return !!config.enableDynamicReward;
}

export function enableIban() {
  return !!config.enableRefundFlow.iban;
}

export function enablePaypal() {
  return !!config.enableRefundFlow.paypal;
}

export function enableLater() {
  return !!config.enableRefundFlow.later;
}

export function collectInfoBefore() {
  return config.getUserInfo.type === 'before';
}

export function collectInfoAfter() {
  return config.getUserInfo.type === 'after';
}

export function enableEmail() {
  return config.getUserInfo.email;
}

export function enableName() {
  return config.getUserInfo.name;
}
export function enableStreet() {
  return config?.getUserInfo?.street;
}

export function isEcommerceMode() {
  return getHolderRef() && !config.isModeInStore && !receiptInStoreMode();
}

export function isModeInStore() {
  return config.isModeInStore;
}
export function isReceiptMode() {
  return receiptInStoreMode() && config.isModeInStore && !getHolderRef();
}

export function isPinMode() {
  return config.isModeInStore && !getHolderRef() && !receiptInStoreMode();
}

export const hasHomeSpotlight = !!config.homeSpotlight;
export const landingNoOptin = !!config.landingNoOptin;

/**
 * Gets the offer type from the shop configuration.
 * If the offer type is not specified, it defaults to "default".
 * @returns {("default"|"legacy")}  The offer type.
 */
export const offerType = config.offerType || 'default';

export const isCollectEnabled =
  config?.collectFields && config?.collectFields.length > 0;
export const isEmailCollected =
  config?.collectFields && config?.collectFields.includes('email');
export const isNameCollected =
  config?.collectFields && config.collectFields.includes('name');
export const isCityCollected =
  config?.collectFields && config.collectFields.includes('city');
export const isNewsletterCollected =
  config?.collectFields && config.collectFields.includes('news_optin_partners');
export const isLegalCollected =
  config?.collectFields && config.collectFields.includes('optin_legal');
export const newsletterText = config?.collectNewsletterMessage;
export const defaultBackgroundColor = config?.defaultBackgroundColor;
export const errorMessage = config?.ErrorMessage;
