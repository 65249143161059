export const faq = [
  {
    question: 'Quelles sont les conditions pour participer au concours',
    answer:
      'Vous devez être agé de plus de 18 ans, résider en France et effectuer un achat dans une enseigne du territoire français. Pour participer, il est indispensable de respecter les conditions citées dans le règlement du jeu, pendant la période indiquée.',
  },
  {
    question: 'Combien de fois puis-je participer ?',
    answer:
      'Chaque commande ou achat effectué donne droit a une chance de participer.',
  },
  {
    question: 'Que puis-je gagner ?',
    answer:
      'Un nombre limité de gains est mis en jeu, comme indiqué dans le règlement du concours',
  },
  {
    question: 'Comment savoir si j’ai gagné ?',
    answer:
      "Une fois que vous avez cliqué sur le bouton « jouer » du jeu et activé la machine à sous virtuelle, vous verrez un message contenant immédiatement le résultat de votre participation et indiquant si vous avez gagné ou non l'un des gain.",
  },
  {
    question: 'Quand recevrai-je mon gain ?',
    answer:
      "Si vous avez gagné et que vous avez consulté le message correspondant, vous recevrez votre gain dans les semaines suivantes, sous réserve de la vérification de la validité de votre participation. Le gain vous sera envoyé en format numérique à l'adresse e-mail indiquée lors de votre inscription sur le site de jeu.",
  },
  {
    question:
      'Que se passe-t-il si j’annule tout ou partie de mon achat après avoir gagné un gain ?',
    answer:
      "Toute annulation totale ou partielle, pour quelque raison que ce soit, de l'achat effectué entraînera l'annulation de la participation au concours et, éventuellement, l'annulation du gain gagné : dans ce cas, le gain ne sera pas reconnu.",
  },
  {
    question: 'Comment utiliser mon gain ?',
    answer:
      'Lors de la réception du mail de confirmation du gain, vous recevrez également toutes les informations nécessaires à son retrait ou utilisation, y compris les éventuelles limitations et la durée de validité.',
  },
];
