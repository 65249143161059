import React, { useState } from 'react';
import './assets/style.scss';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { getCGU } from '../../../utils/catalinaRequests';
import packageJson from '../../../../package.json';
import legal from './../../../shop/legals/legal.json';
import config from './../../../shop/config/config.json';

function Footer() {
  const [CGU, setCGU] = useState();
  const [t] = useTranslation('message');
  setTimeout(function () {
    setCGU(getCGU());
  }, 1000);

  const appEnv = process.env.REACT_APP_ENV;

  console.log(process.env.REACT_APP_ENV);

  return (
    <div className="footer">
      <div className="options">
        <Link to="legal/help">{t('footer.help')}</Link>
        {CGU && (
          <a href={CGU} target="_blank" rel="noreferrer">
            {t('footer.conditions')}
          </a>
        )}
        <Link to="legal/legalNotices">{t('footer.legal')}</Link>
        {legal.dataProtection ? (
          <Link to="legal/dataProtection">{t('footer.data')}</Link>
        ) : null}
        <Link to="legal/personalData">{t('footer.personalData')}</Link>
        <Link to="legal/condition">{t('footer.condition')}</Link>
        {config.contact_email ? (
          <a href={`mailto:${config.contact_email}`}>Contact</a>
        ) : null}
      </div>
      {appEnv !== 'prod' ? (
        <p className="version-footer">{`v${packageJson.version}  ${packageJson.releasedate}`}</p>
      ) : null}
    </div>
  );
}

export default Footer;
